<template>
    <CRow>
        <CCol col="12" xl="12">
            <CCard>
                <CCardHeader>
                    <CCol col="6" style="font-size: 25px;">تعديل المدينة</CCol>
                </CCardHeader>
                <CCardBody>
                    <div>
                        <div class="row">
                            <div class="col-md-6">
                                <form @submit.prevent="UpdateCity">
                                    <div class="form-group">
                                        <label style="padding:5px;">الاسم <span class="star">*</span></label>
                                        <input type="text" class="form-control" v-model="city.name" required><!--v-model="category.name" -->
                                    </div>
                                    <div class="form-group">
                                        <label for="provinces">
                                            الأحجام
                                            <span class="star">*</span>
                                        </label> 
                                        <multiselect v-model="selected" :value="selected" id="provinces" :options="provinces" :searchable="true" selectLabel="اضغط لإختيار هذا العنصر" :close-on-select="true"
                                            openDirection = "bottom" selectedLabel=" العنصر المحدد" deselectLabel="اضغط لإلغاء اختيار هذا العنصر"
                                            class=" required"  placeholder="اختر..." label="name" track-by="name" required> <span slot="noOptions">اللائحة فارغة</span> <span slot="noResult">لا توجد نتيجة</span>
                                        </multiselect>
                                        <!-- <select v-model="selected" id="provinces" class="form-control" required>
                                            <option v-for="option in provinces" v-bind:value="option.id">
                                                {{ option.name }}
                                            </option>
                                        </select> -->
                                    </div>
                                    <button type="submit" class="btn btn-success" >تحديث البيانات</button>
                                    &emsp;
                                    <CButton color="warning" @click="goBack">رجوع</CButton>
                                </form>
                            </div>
                        </div>
                    </div>
                </CCardBody>
            </CCard>
        </CCol>
    </CRow>
</template>

<script>
    import Multiselect from 'vue-multiselect'
    import swal from 'sweetalert'
    import $ from 'jquery'
    export default {
        name : 'EditCity',
        data() {
            return {
                city: [],
                image:'',
                provinces:[],
                selected: '',
            }
        },
        created() {
            console.log(this.$route.params.id);
            this.$http
                .get(`${process.env.VUE_APP_URL}cities/${this.$route.params.id}/edit`)
                .then((response) => {
                    this.city = response.data.data.city;
                    this.selected = response.data.data.city.province_id;
                    this.selected = this.city.province;
                });
                let provinces = [];
                this.$http
                    .get(`${process.env.VUE_APP_URL}provinces-list`)
                    .then((response) => {
                        $.each(response.data.data, function(key, value) {
                            provinces.push({id:value.id,name:value.name})
                        });
                        this.provinces = provinces;
                    });
        },
        methods: {
            UpdateCity() {
                let formData = new FormData();
                formData.append('name', this.city.name);
                formData.append('_method', 'PUT');
                if (this.selected.id)
                    formData.append('province_id', this.selected.id);
                this.$http
                    .post(`${process.env.VUE_APP_URL}cities/${this.$route.params.id}`,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        })
                    .then((response) => {
                        if(!response.data.error)
                        {
                            swal({ title:response.data.message, buttons: "تم"});
                        }
                        else
                        {
                            swal({ title:response.data.message, buttons: "تم"});
                        }
                    });
            },
            goBack() {
                this.citiesOpened ? this.$router.go(-1) : this.$router.push({path: '/cities'})
            },
        }
    }
</script>